/* eslint-disable @typescript-eslint/no-misused-promises */
import React from "react";
import { Breadcrumb, Button, Select, Spin, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { withRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import styles from "@aspen/theme/Wallet.module.less";
import type {
  IBankList,
  IBusinessVerificationHeaderModel,
  ICurrencyAsset,
  IInjectProps,
  IReqSecurityVerifyHeader,
  IWalletWithdrawAddress,
  IWalletWithdrawWhiteInfo
} from "@aspen/model";
import { IBindingBusinessType, WITHDRAW_WHITELIST_TYPE, IUserEventEnums } from "@aspen/model";
import {
  applyWithdraw,
  checkWithdrawAddressFormat,
  getAssetCurrencyConfig,
  getWithdrawAddressList,
  getWithdrawEnableWhiteInfo,
  userEventCheck
} from "@aspen/services";
import {
  computeNumber,
  convertUSD2USDC,
  decimalPointNoMoreX,
  getQueryValue,
  i18nUtil,
  numberToThousands,
  reportEvent,
  HISTORY_PATHS,
  OPERATE_CUSTOMER_EMAIL,
  GA_EVENT_NAME,
  USER_AUTH,
  USER_ROLE_POWER,
  WALLET_PATHS,
  GA_EVENT_TAG
} from "@aspen/libs";
import { message, WithTrimInput, ModalBase } from "@aspen/ui";
import {
  WhitelistAlert,
  History,
  ModalSelectAddress,
  ModalBusinessVerification,
  WithRiskReminder
} from "@aspen/widgets";

const { Option } = Select;
interface IProps extends IInjectProps {
  handleCheckVerify: () => void;
}

interface IState {
  loading: boolean;
  showBusinessVerification: boolean;
  bankList: IBankList[];
  addressInfo: string;
  withdrawAmount: string | number;
  showAddBankModal: boolean;
  octOrderId: string;
  showSafetyVerificationModal: boolean; //提去邮箱认证
  showGoogleAuthModal: boolean;
  oneTimePaceNum: number; // 每次提币的最大数量
  oneDayPaceNum: number; // 每天提币的最大数量
  leastPaceNum: number; // 每次提币的最小数量
  balance: number; //当前币种余额
  xrpTag: string;
  feeType: string; //当前费率收取类型- PERCENTAGE:按照百分比, FIXED:按照固定值, MIXED:混合计算取两者较大的一项
  feeRate: number; //按比例收取时的费率
  feeCurrecy: string | number; //费用固定时的金额
  chainList: ICurrencyAsset[];
  currentChain: string;
  showApplicationModal: boolean; // 代客操作提币申请modal
  addressList: Array<IWalletWithdrawAddress>;
  enableWhiteInfo: IWalletWithdrawWhiteInfo | null;
  showDisableWithdrawVisiable: boolean;
  addressFormat: boolean;
  selectAddressVisible: boolean;
  showVerifyTipModal: boolean;
  verifyTip: string;
}
class Withdraw extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showBusinessVerification: false,
      bankList: [],
      addressInfo: "",
      withdrawAmount: "",
      octOrderId: "",
      showAddBankModal: false,
      showSafetyVerificationModal: false,
      showGoogleAuthModal: false,
      oneTimePaceNum: 0,
      oneDayPaceNum: 0,
      leastPaceNum: 0,
      balance: 0,
      xrpTag: "",
      feeType: "",
      feeRate: 0,
      feeCurrecy: "",
      chainList: [],
      currentChain: "",
      showApplicationModal: false,
      addressList: [],
      enableWhiteInfo: null,
      showDisableWithdrawVisiable: false,
      addressFormat: true,
      selectAddressVisible: false,
      showVerifyTipModal: false,
      verifyTip: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.initData();
  }

  // 初始化数据
  initData = () => {
    this.setState({
      loading: true
    });
    userEventCheck({ userEventEnum: IUserEventEnums.ApplyWithdraw }).then((res) => {
      if (res?.code != "0") {
        const errors = [
          "customer.not.allowed.withdraw.after.modify.password",
          "customer.not.allowed.withdraw.after.modify.bind"
        ];
        if (errors.includes(res?.msg)) {
          this.setState({
            loading: false,
            showVerifyTipModal: true,
            verifyTip: res?.msg
          });
        } else {
          this.loadPageData();
        }
      } else {
        this.loadPageData();
      }
    });
  };

  loadPageData = () => {
    const currency = this.props.router?.query?.currency?.toString() ?? getQueryValue("currency");
    this.fetchAssetList(currency);
    // 获取白名单信息
    getWithdrawEnableWhiteInfo().then((res) => {
      if (res?.code == "0") {
        if (!res.data?.withdrawEnable) {
          this.setState({ showDisableWithdrawVisiable: true });
        }
        this.setState({ enableWhiteInfo: res?.data });
      }
    });
  };

  // 获取当前链的地址列表
  getAddressList = (asset: string) => {
    // 后端接口此处不分页 预留limit防止以后改为分页
    let params = {
      asset,
      limit: 50
    };
    getWithdrawAddressList(params).then((res) => {
      if (res?.code == "0") {
        this.setState({ addressList: res?.data || [] });
      }
    });
  };

  // 获取链列表
  fetchAssetList = (currency: string) => {
    const intl = i18nUtil.t();
    const param = {
      currency
    };
    getAssetCurrencyConfig(param)
      .then((res) => {
        if (res?.code == "0") {
          const chainList = res.data[0]?.assets?.filter((item) => item.chainType !== "FIAT") || [];
          chainList?.[0] && this.setLimitInfo(chainList[0]);
          chainList?.[0] && this.getAddressList(chainList?.[0]?.asset);
          this.setState({
            chainList,
            currentChain: chainList?.[0]?.asset || "",
            balance: decimalPointNoMoreX(res.data[0]?.available)
          });
        } else {
          message.error(intl?.[res?.msg] ?? res?.msg);
        }
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  // 获取提币限额
  setLimitInfo = (info: ICurrencyAsset) => {
    // 此处和后台讨论 ，因中台优化，单次限额取period === "ONCE"并且category === "ASSET"，单日限额取period === "DAILY"并且category === "CURRENCY"
    const oneTimeLimitInfo = info.quotas.filter(
      (e) => e.period === "ONCE" && e.category === "ASSET"
    )[0];
    const oneDayLimitInfo = info.quotas.filter(
      (e) => e.period === "DAILY" && e.category === "CURRENCY"
    )[0];
    this.setState({
      leastPaceNum: oneTimeLimitInfo?.minAmount || 0,
      oneTimePaceNum: oneTimeLimitInfo?.maxAmount || 0,
      oneDayPaceNum: oneDayLimitInfo?.maxAmount || 0,
      feeType: info?.withdrawFeeType,
      feeRate: info?.withdrawFeeRate,
      feeCurrecy: info?.withdrawFeeAmount
    });
  };

  getText(): { [propName: string]: any } {
    // 不修改
    const intl = i18nUtil.t();
    const location: string = intl["kyc.certify.location"];
    const wallet: string = intl["wallet"];
    const limit: string = intl["wallet.withdraw.limit"];
    const amount: string = intl["wallet.withdraw.amount"];
    const max: string = intl["wallet.withdraw.max"];
    const amountPlaceholder: string = intl["wallet.withdraw.amount.placeholder"];
    const confirm: string = intl["button.confirm"];
    const noticeTitle: string = intl["wallet.withdraw.notice"];
    const noticeList: string[] = intl["wallet.withdraw.notice.content"];

    const withdrawBtcAddress: string = intl["wallet.withdraw.btc.address"];
    const withdrawBtcAddressPlaceholder: string = intl["wallet.withdraw.btc.address.placeholder"];
    const withdrawSuccess: string = intl["wallet.withdraw.success.tips"];
    const withdrawFailed: string = intl["wallet.withdraw.failed.tips"];
    const tagText: string = intl["wallet.deposit.btc.tag"];
    const availableText: string = intl["available"];
    const dayText: string = intl["overview.myAsset.day"];
    const selectNetworkText: string = intl["wallet.deposit.btc.select.network"];
    const applicationModalTitle: string = intl["wallet.withdraw.application.title"];
    const applicationModalOkText: string = intl["wallet.withdraw.application.OkText"];
    const OKText: string = intl["button.ok"];
    return {
      location,
      wallet,
      limit,
      amount,
      max,
      amountPlaceholder,
      confirm,
      noticeTitle,
      noticeList,
      withdrawBtcAddress,
      withdrawBtcAddressPlaceholder,
      withdrawSuccess,
      withdrawFailed,
      tagText,
      availableText,
      dayText,
      selectNetworkText,
      applicationModalTitle,
      applicationModalOkText,
      OKText
    };
  }

  // 修改电子货币地址
  handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      addressInfo: e.target.value
    });
  };

  // XRP Tag输入
  handleChangeXrpTag = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      xrpTag: e.target.value
    });
  };
  // 校验提币地址格式
  checkAddress = () => {
    const { addressInfo, currentChain, xrpTag } = this.state;
    if (addressInfo === "") {
      this.setState({ addressFormat: true });
      return;
    }
    if (currentChain === "XRP" && (addressInfo === "" || xrpTag === "")) return true;

    const params = {
      asset: currentChain,
      address: addressInfo,
      memo: xrpTag
    };
    checkWithdrawAddressFormat(params).then((res) => {
      if (res?.code == "0") {
        if (res.data === true) {
          this.setState({ addressFormat: true });
        } else {
          this.setState({ addressFormat: false });
        }
      }
    });
  };

  // 修改金额
  handleChangeAmount = (e) => {
    let params = e.target.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, "."); //数字
    const value = params.replace(/^(-)*(\d+)\.(\d{1,8}).*$/, "$1$2.$3"); //八位小数
    this.setState({ withdrawAmount: value });
  };

  handleMaxAmount = () => {
    this.setState({ withdrawAmount: this.state.balance });
  };

  handleChangeChain = (value, option) => {
    const asset = this.state.chainList[option.key];
    this.setLimitInfo(asset);
    this.getAddressList(value);
    this.setState({
      currentChain: value,
      addressInfo: ""
    });
  };

  // 提交信息
  submitInfo = (param: IBusinessVerificationHeaderModel) => {
    this.withdrawFetch(param);
  };

  // 数字货币提取
  withdrawFetch = (verifyParam: IBusinessVerificationHeaderModel) => {
    const intl = i18nUtil.t();
    const { withdrawAmount, addressInfo, xrpTag, currentChain } = this.state;
    const { withdrawSuccess, withdrawFailed } = this.getText();
    this.setState({ loading: true });
    let headerParams: IReqSecurityVerifyHeader = {
      businessType: IBindingBusinessType.applyWithdraw,
      smsType: 0
    };
    headerParams = { ...headerParams, ...verifyParam };

    const param = {
      currency: currentChain, // 传入链的地址
      address: addressInfo, // 地址
      outerBusinessId: "",
      memo: xrpTag, // 备注 xrp
      amount: withdrawAmount // 金额
    };
    applyWithdraw(param, headerParams)
      .then((res) => {
        if (res?.code == "0") {
          const user_auth = (typeof window != "undefined" && localStorage.getItem(USER_AUTH)) || "";
          if (USER_ROLE_POWER[user_auth]?.actionForOthers) {
            this.setState({
              showApplicationModal: true
            });
          } else {
            message.success(withdrawSuccess, 2).then(() => {
              this.props.router.push(WALLET_PATHS.WALLET);
            });
          }
        } else {
          let msg = intl?.[res?.msg];
          if (!msg) {
            msg = intl?.[res?.code] ?? withdrawFailed;
          }
          message.error(msg);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    reportEvent({
      moduleName: GA_EVENT_NAME.whithdraw.crypto,
      detailParams: { [currentChain]: withdrawAmount }
    });
  };

  // 判断输入金额是否符合规范
  checkAmount = (value) => {
    const intl = i18nUtil.t();
    const currency = this.props.router?.query?.currency?.toString() ?? "";
    const { leastPaceNum, oneTimePaceNum, oneDayPaceNum, balance, addressList, addressInfo } =
      this.state;
    // 如果地址是白名单地址 不交验限额
    if (
      addressList?.some(
        (e: IWalletWithdrawAddress) =>
          e.address === addressInfo && e.type === WITHDRAW_WHITELIST_TYPE.whitelist
      )
    ) {
      if (Number(value) > Number(balance)) {
        return `${intl["wallet.withdraw.amount.error.tips.third"]} ${decimalPointNoMoreX(balance)}`;
      }
      return "success";
    }
    if (value < leastPaceNum) {
      return `${intl["wallet.withdraw.amount.error.tips.first"]} ${numberToThousands(
        decimalPointNoMoreX(leastPaceNum)
      )}`;
    }

    const max = Math.min(oneTimePaceNum, oneDayPaceNum, balance);
    if (value > max) {
      return `${intl["wallet.withdraw.amount.error.tips.second"]} ${numberToThousands(
        decimalPointNoMoreX(max)
      )} ${currency}`;
    }

    return "success";
  };

  // 计算输入金额费率
  calculateFee: () => number | string = () => {
    const { withdrawAmount, feeType, feeRate, feeCurrecy } = this.state;
    let count: number = 0;

    switch (feeType) {
      case "PERCENTAGE":
        count = computeNumber(Number(withdrawAmount), "*", feeRate).result;
        break;
      case "FIXED":
        count = Number(feeCurrecy);
        break;
      case "MIXED":
        count = Math.max(
          computeNumber(Number(withdrawAmount), "*", feeRate).result,
          Number(feeCurrecy)
        );
        break;
    }

    return numberToThousands(count?.toFixed(8));
  };

  handleSubmit = () => {
    this.setState({
      showBusinessVerification: true
    });
  };

  handleVerifyModal = () => {
    this.setState({ showVerifyTipModal: false });
    this.props.router.back();
  };

  render(): React.ReactNode {
    const {
      location,
      wallet,
      limit,
      amount,
      max,
      confirm,
      noticeTitle,
      noticeList,
      withdrawBtcAddress,
      withdrawBtcAddressPlaceholder,
      tagText,
      availableText,
      dayText,
      selectNetworkText,
      applicationModalTitle,
      applicationModalOkText,
      OKText
    } = this.getText();

    const {
      loading,
      oneDayPaceNum,
      balance,
      withdrawAmount,
      addressInfo,
      chainList,
      currentChain,
      showApplicationModal,
      showDisableWithdrawVisiable,
      addressFormat,
      enableWhiteInfo,
      addressList,
      selectAddressVisible,
      showBusinessVerification,
      showVerifyTipModal,
      verifyTip
    } = this.state;

    const currency = this.props.router?.query?.currency?.toString() ?? "";
    const intl = i18nUtil.t();
    // 代客操作时 不显示白名单相关入口 直接点击按钮关闭弹窗
    const user_auth = (typeof window != "undefined" && localStorage.getItem(USER_AUTH)) || "";
    const showWhiteAddress = !USER_ROLE_POWER[user_auth]?.actionForOthers;

    return (
      <section className={styles.withdraw}>
        <Spin spinning={loading}>
          <Breadcrumb>
            <Breadcrumb.Item>{location}:</Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link href={WALLET_PATHS.WALLET}>{wallet}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {i18nUtil.formatMessage(
                { id: "wallet.withdraw.title" },
                {
                  currentCoin: convertUSD2USDC(currency)
                }
              )}
            </Breadcrumb.Item>
          </Breadcrumb>

          <div className={styles.withdrawContent}>
            <p className={styles.title}>
              {i18nUtil.formatMessage(
                { id: "wallet.withdraw.title" },
                {
                  currentCoin: convertUSD2USDC(currency)
                }
              )}
            </p>
            {
              // 当前地址为白名单地址时 不限时限额并且不校验限额
              addressList?.some(
                (e: IWalletWithdrawAddress) =>
                  e.address === addressInfo && e.type === WITHDRAW_WHITELIST_TYPE.whitelist
              ) ? null : (
                <div className={styles.limitArea}>
                  <span>{limit}</span>
                  <span>
                    {`${numberToThousands(decimalPointNoMoreX(oneDayPaceNum))} ${convertUSD2USDC(
                      currency
                    )}`}
                    / {dayText}
                  </span>
                </div>
              )
            }
            <div className={styles.chainTypeArea}>
              <p className={styles.label}>{selectNetworkText}</p>
              <Select
                className={styles.selectCoin}
                value={currentChain}
                style={{ width: 100 }}
                onChange={(e, option) => this.handleChangeChain(e, option)}>
                {chainList.map((item, index) => {
                  return (
                    <Option key={index} value={item.asset}>
                      {convertUSD2USDC(item.chainName)}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div className={styles.addressArea}>
              <div className={styles.topArea}>
                <span>{withdrawBtcAddress}</span>
                {showWhiteAddress ? (
                  <div>
                    <span
                      onClick={() =>
                        this.props.router.push(WALLET_PATHS.WALLET_WITHDRAW_WHITELIST_MANAGEMENT)
                      }>
                      {intl["wallet.withdraw.btc.address.manage"]}
                    </span>
                    <Tooltip title={intl["wallet.withdraw.btc.address.manage.tips"]}>
                      <InfoCircleOutlined style={{ paddingLeft: "5px", cursor: "pointer" }} />
                    </Tooltip>
                  </div>
                ) : null}
              </div>
              <div className={styles.addressContent}>
                {enableWhiteInfo?.enable ? (
                  <div
                    className={styles.addressView}
                    onClick={() => {
                      this.setState({ selectAddressVisible: true });
                    }}>
                    {addressInfo ? addressInfo : withdrawBtcAddressPlaceholder}
                  </div>
                ) : (
                  <WithTrimInput
                    bordered={false}
                    onChange={this.handleChangeAddress}
                    placeholder={withdrawBtcAddressPlaceholder}
                    onBlur={this.checkAddress}
                    value={addressInfo}
                  />
                )}
                <div
                  className={styles.iconContent}
                  onClick={() => {
                    reportEvent({
                      joinedTag: GA_EVENT_TAG.Modal,
                      moduleName: GA_EVENT_NAME.whithdraw.selectAddress
                    });
                    this.setState({ selectAddressVisible: true });
                  }}>
                  <Image
                    unoptimized
                    alt=""
                    width="16"
                    height="16"
                    src={require("@aspen/assets/images/addressList.webp").default}
                  />
                </div>
              </div>
              {!addressFormat && (
                <div className={styles.errorTips}>
                  <p>{intl["wallet.withdraw.white.address.error"]}</p>
                </div>
              )}
            </div>
            {enableWhiteInfo?.enable ? <WhitelistAlert /> : null}
            {currency == "XRP" && (
              <div className={styles.xrpTag}>
                <p>{tagText}</p>
                <WithTrimInput
                  bordered={false}
                  onChange={this.handleChangeXrpTag}
                  onBlur={this.checkAddress}
                  placeholder={tagText}
                />
              </div>
            )}
            <div className={styles.amountArea}>
              <div className={styles.topArea}>
                <span>{amount}</span>
                <span onClick={this.handleMaxAmount}>{max}</span>
              </div>
              <div className={styles.bottomArea}>
                <div className={styles.amountTop}>
                  <WithTrimInput
                    bordered={false}
                    placeholder={`${numberToThousands(balance)} ${availableText}`}
                    className={styles.amountInput}
                    value={withdrawAmount}
                    onChange={this.handleChangeAmount}
                    suffix={convertUSD2USDC(currency)}
                  />
                  {withdrawAmount != "" && this.checkAmount(withdrawAmount) != "success" && (
                    <div className={styles.limitTips}>
                      <p>{this.checkAmount(withdrawAmount)}</p>
                    </div>
                  )}
                </div>
                {withdrawAmount != "" && this.checkAmount(withdrawAmount) == "success" && (
                  <>
                    <span className={styles.feeTips}>
                      {`${i18nUtil.formatMessage(
                        { id: "wallet.withdraw.fee" },
                        {
                          fee: this.calculateFee()
                        }
                      )} ${convertUSD2USDC(currency)}`}
                    </span>
                    <span className={styles.mainColor}>{intl["wallet.withdraw.fee.tips"]}</span>
                  </>
                )}
              </div>
            </div>
            <Button
              disabled={
                addressInfo &&
                withdrawAmount &&
                currentChain &&
                this.checkAmount(withdrawAmount) === "success" &&
                enableWhiteInfo?.withdrawEnable &&
                addressFormat
                  ? false
                  : true
              }
              className={styles.confirmBtn}
              onClick={this.handleSubmit}
              type="primary">
              {confirm}
            </Button>
            <div className={styles.noticeArea}>
              <div className={styles.title}>{noticeTitle}</div>
              <ul className={styles.listArea}>
                {noticeList.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>

          {selectAddressVisible ? (
            <ModalSelectAddress
              visible={selectAddressVisible}
              handleCancel={() => {
                this.setState({ selectAddressVisible: false });
              }}
              handleOk={(address) => {
                this.setState({ addressInfo: address, addressFormat: true });
              }}
              enableWhite={!!enableWhiteInfo?.enable}
              addressList={addressList}
            />
          ) : null}
          {showApplicationModal ? (
            <ModalBase
              title={applicationModalTitle}
              centered
              closable={false}
              maskClosable={false}
              visible={showApplicationModal}
              okText={applicationModalOkText}
              okButtonProps={{ style: { width: 150 } }}
              cancelText={OKText}
              onOk={() => {
                this.props.router.replace(HISTORY_PATHS.HISTORY_APPLICATION_DETAIL);
              }}
              onCancel={() => {
                this.props.router.replace(WALLET_PATHS.WALLET);
              }}>
              <div
                className={styles.applicationModal}
                dangerouslySetInnerHTML={{
                  __html: i18nUtil.formatMessage(
                    { id: "wallet.withdraw.application.content" },
                    {
                      email:
                        typeof window != "undefined" && localStorage.getItem(OPERATE_CUSTOMER_EMAIL)
                    }
                  )
                }}
              />
            </ModalBase>
          ) : null}
          {showBusinessVerification ? (
            <ModalBusinessVerification
              confirmLoading={loading}
              visible={showBusinessVerification}
              businessType={IBindingBusinessType.applyWithdraw}
              confirm={(res) => {
                this.submitInfo(res);
              }}
              cancel={() => {
                this.setState({
                  showBusinessVerification: false
                });
              }}
            />
          ) : null}
          {showDisableWithdrawVisiable ? (
            <ModalBase
              buttonType="only"
              title={intl["wallet.withdraw.suspended.title"]}
              open={showDisableWithdrawVisiable}
              okText={intl["button.ok"]}
              onOk={() => {
                this.setState({ showDisableWithdrawVisiable: false });
                this.props.router.back();
              }}
              onCancel={() => {
                this.setState({ showDisableWithdrawVisiable: false });
                this.props.router.back();
              }}>
              <p>{intl["wallet.withdraw.suspended.content"]}</p>
            </ModalBase>
          ) : null}
          {showVerifyTipModal ? (
            <ModalBase
              buttonType="only"
              open={showVerifyTipModal}
              title={intl["modal.confirm.title"]}
              onOk={this.handleVerifyModal}
              okText={intl["button.confirm"]}
              onCancel={this.handleVerifyModal}>
              <p className={styles.verifyTipStyle}>{intl[verifyTip]}</p>
            </ModalBase>
          ) : null}
        </Spin>
        <History showViewAll type={2} currency={currency} fiat={false} />
      </section>
    );
  }
}

export const PageWithdraw = WithRiskReminder(withRouter(Withdraw));
